import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

export default () => (
  <Layout>
    <main id="main" style={{ marginLeft: 0 }}>
      <section id="top" className="cover">
        <div className="container">
          <header>
            <h2 className="alt">Thanks for reaching out!</h2>
            <p>I will get back to you as soon as possible.</p>
            <Link to="/">Back to Home</Link>
          </header>
        </div>
      </section>
    </main>
  </Layout>
);
